import { useGlobalConfig } from '../hooks/configContext';
import { Cloud$BusinessUnitType } from '../types/cloudEnums';

type Props = {
  includeUnits?: string[];
  excludeUnits?: string[];
  includeAffiliates?: string[];
  excludeAffiliates?: string[];
  excludedTypes?: Cloud$BusinessUnitType[];
  onlyCentral?: boolean; // Only show this for central units
  children: React.ReactNode;
};

const BusinessUnitWrapper = ({
  children,
  includeUnits,
  excludeUnits,
  includeAffiliates,
  excludeAffiliates,
  onlyCentral,
  excludedTypes,
}: Props) => {
  const { BU_CONFIG } = useGlobalConfig();

  if (Array.isArray(excludedTypes) && excludedTypes.includes(BU_CONFIG.type)) {
    return null;
  }

  if (
    onlyCentral &&
    !['OWN_AND_OPERATE', 'WHITE_LABEL'].includes(BU_CONFIG.type)
  ) {
    return null;
  }
  if (!!excludeUnits && excludeUnits.includes(BU_CONFIG.slug)) {
    return null;
  }
  if (!!includeUnits && !includeUnits.includes(BU_CONFIG.slug)) {
    return null;
  }

  const { matchedPartner } = BU_CONFIG;
  if (matchedPartner?.type === 'AFFILIATE') {
    const affiliateUtmSources = matchedPartner.config.utm_sources;

    if (
      includeAffiliates &&
      !affiliateUtmSources.some((utm) => includeAffiliates.includes(utm))
    ) {
      return null;
    }

    if (excludeAffiliates?.some((utm) => affiliateUtmSources.includes(utm))) {
      return null;
    }
  }

  return <>{children}</>;
};

export default BusinessUnitWrapper;
