import { FormattedMessage as T } from 'react-intl';
import ModernHouseWithSolarPanels from '../../images/illustrations/2022/ModernHouseWithSolarPanels';
import { isChatAvailable } from '../../lib/chatUtils';
import ErrorPage from '../ErrorPage/ErrorPage';
import OpenChat from '../OpenChatButton/OpenChat';

import m from './messages';

type Props = {
  children?: React.ReactNode;
};

const FullScreenErrorPage = ({ children }: Props) => {
  const defaultMessage = isChatAvailable() ? (
    <T
      values={{
        chat: <OpenChat className="text-white" />,
      }}
      {...m.errorDescription}
    />
  ) : (
    <T {...m.errorDescriptionWhenChatIsDisabled} />
  );
  return (
    <ErrorPage.Layout className="bg-blue-85">
      <div className="mb-12 md:pr-52">
        <ErrorPage.Heading className="text-white">
          <T {...m.somethingWentWrong} />
        </ErrorPage.Heading>

        <p className="text-base text-blue-10">{children || defaultMessage}</p>
      </div>
      <ErrorPage.Illustration>
        <ModernHouseWithSolarPanels variant="night" />
      </ErrorPage.Illustration>
    </ErrorPage.Layout>
  );
};
export default FullScreenErrorPage;
