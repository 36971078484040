import { defineMessages } from 'react-intl';

const I18N_NAMESPACE = 'Navigation';

export default defineMessages({
  aboutUs: {
    id: `${I18N_NAMESPACE}.aboutUs`,
    defaultMessage: 'About us',
  },
  myPage: {
    id: `${I18N_NAMESPACE}.myPage`,
    defaultMessage: 'My page',
  },
  economyPageName: {
    id: `${I18N_NAMESPACE}.economyPageName`,
    defaultMessage: 'Billing overview',
  },
  accountPageName: {
    id: `${I18N_NAMESPACE}.accountPageName`,
    defaultMessage: 'Account',
  },
  logout: {
    id: `${I18N_NAMESPACE}.logout`,
    defaultMessage: 'Log out of My Page',
  },
  contactUs: {
    id: `${I18N_NAMESPACE}.contactUs`,
    defaultMessage: 'Contact us',
  },
  positions: {
    id: `${I18N_NAMESPACE}.positions`,
    defaultMessage: 'Careers',
  },
  becomeAnInstaller: {
    id: `${I18N_NAMESPACE}.becomeAnInstaller`,
    defaultMessage: 'Become an installer',
  },
  becomePartner: {
    id: `${I18N_NAMESPACE}.becomePartner`,
    defaultMessage: 'Vuoi diventare un venditore Otovo?',
  },
  transparencyAct: {
    id: `${I18N_NAMESPACE}.transparencyAct`,
    defaultMessage: 'Transparency Act',
  },
  support: {
    id: `${I18N_NAMESPACE}.support`,
    defaultMessage: 'Support',
  },
  helpCenter: {
    id: `${I18N_NAMESPACE}.helpCenter`,
    defaultMessage: 'Help Center',
  },
  chatWithUs: {
    id: `${I18N_NAMESPACE}.chatWithUs`,
    defaultMessage: 'Chat with us',
  },
  faqTitle: {
    id: `${I18N_NAMESPACE}.faqTitle`,
    defaultMessage: 'FAQ',
  },
  ourConditions: {
    id: `${I18N_NAMESPACE}.ourConditions`,
    defaultMessage: 'Terms & Conditions',
  },
  ourLeasingConditions: {
    id: `${I18N_NAMESPACE}.ourLeasingConditions`,
    defaultMessage: 'Leasing Terms & Conditions',
  },
  termsOfUse: {
    id: `${I18N_NAMESPACE}.termsOfUse`,
    defaultMessage: 'Terms of use',
  },
  privacyTitle: {
    id: `${I18N_NAMESPACE}.privacyTitle`,
    defaultMessage: 'Privacy Policy',
  },
  imprintTitle: {
    id: `${I18N_NAMESPACE}.imprintTitle`,
    defaultMessage: 'Imprint',
  },
  blogTitle: {
    id: `${I18N_NAMESPACE}.blogTitle`,
    defaultMessage: 'Otovo Blog',
  },
  locatorPageTitle: {
    id: `${I18N_NAMESPACE}.locatorPageTitle`,
    defaultMessage: 'Places we install',
  },
  partnerPageTitle: {
    id: `${I18N_NAMESPACE}.partnerPageTitle`,
    defaultMessage: 'Partner with us',
  },
  wordMenu: {
    id: `${I18N_NAMESPACE}.wordMenu`,
    defaultMessage: 'Menu',
  },
  storefront: {
    id: `${I18N_NAMESPACE}.storefront`,
    defaultMessage: 'Homepage',
  },
  leasingInstallation: {
    id: `${I18N_NAMESPACE}.leasingInstallation`,
    defaultMessage: 'Lease with us',
  },
  buyNowInstallation: {
    id: `${I18N_NAMESPACE}.buyNowInstallation`,
    defaultMessage: 'Buy from us',
  },
  getPriceEstimate: {
    id: `${I18N_NAMESPACE}.getPriceEstimate`,
    defaultMessage: 'Get price estimate',
  },
  cancellationForm: {
    id: `${I18N_NAMESPACE}.cancellationForm`,
    defaultMessage: 'Cancellation form',
  },
  otovoReferralProgram: {
    id: `${I18N_NAMESPACE}.otovoReferralProgram`,
    defaultMessage: 'Refer a friend',
  },
  productPageBurgerMenu: {
    id: `${I18N_NAMESPACE}.productPageBurgerMenu`,
    defaultMessage: 'Our products',
  },
});
