import Link from 'next/link';

import { cn } from '../../lib/classNames';
import { getLinkTag, linkTags } from './getLinkTag';
import NewTabIcon from './NewTabIcon';

type Props = {
  as?: any;
  href?: string | object;
  target?: string;
  children?: React.ReactNode;
  onClick?: any;
  rel?: string;
  locale?: string;
  className?: string;
};

const getPathname = (href?: string | object): string | undefined => {
  if (!href) {
    return undefined;
  }
  if (typeof href === 'object') {
    // Check if the object has a pathname property
    if ('pathname' in href && href.pathname) {
      return href.pathname as string;
    }
    // Default to empty string if we can't find a pathname
    return '';
  }
  return href;
};

const RainbowLink = ({
  as,
  href,
  children,
  target,
  onClick,
  rel,
  locale,
  className,
}: Props) => {
  const pathname = getPathname(href);
  const linkTag = getLinkTag({ as, href: pathname, target });

  // This covers three cases:
  // 1. External links: Full links to other domains (or subdomains). Will trigger full page refresh.
  // 2. Buttons styled as links
  // 3. mailto and tel links

  const style = cn(
    'text-digital font-medium',
    'focus:outline-lilac-1 hover:underline focus:underline hover:underline-offset-4 focus:outline-offset-4',
    className,
  );

  switch (linkTag) {
    case linkTags.ANCHOR:
      return (
        <a
          href={pathname}
          target={target}
          onClick={onClick}
          rel={rel}
          className={style}
        >
          {children}
          {target === '_blank' && <NewTabIcon />}
        </a>
      );

    case linkTags.BUTTON:
      return (
        <button type="button" className={style} onClick={onClick}>
          {children}
        </button>
      );

    default:
      return (
        <Link
          passHref
          href={href}
          scroll={false}
          target={target}
          onClick={onClick}
          rel={rel}
          className={style}
          locale={locale}
        >
          {children}
        </Link>
      );
  }
};

export default RainbowLink;
