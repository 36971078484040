import { FormattedMessage as T, useIntl } from 'react-intl';

import { useGlobalConfig } from '../../hooks/configContext';
import Head from '../Head/Head';

import ErrorPage from '../ErrorPage/ErrorPage';

import { cn } from '../../lib/classNames';
import RainbowLink from '../RainbowLink/RainbowLink';
import messages from './messages';

type Props = {
  children?: React.ReactNode;
  headTitle?: string;
  headDescription?: string;
  title?: React.ReactNode;
  cta?: React.ReactNode;
  status?: number | string;
  illustration?: React.ReactNode;
};

const DefaultChildren = () => {
  const config = useGlobalConfig();

  return (
    <T
      {...messages.notFoundParagraph}
      values={{
        link: (
          <RainbowLink href={config.STOREFRONT_URL}>
            <T {...messages.frontpageRef} />
          </RainbowLink>
        ),
      }}
    />
  );
};

const ErrorStatusPage = ({
  children = <DefaultChildren />,
  headTitle = '',
  headDescription = '',
  status = 404,
  cta,
  title = '',
  illustration,
}: Props) => {
  const intl = useIntl();
  return (
    <ErrorPage.Layout>
      <Head
        title={headTitle || intl.formatMessage(messages.headTitle)}
        description={
          headDescription || intl.formatMessage(messages.headDescription)
        }
      />
      <div className="md:flex">
        <ErrorPage.Heading className="text-peach-60">
          {status}
        </ErrorPage.Heading>
        <div className="md:ml-6">
          <div
            className={cn('md:pl-6', {
              'border-l-grey-20 md:border-l-2 ': !!status,
            })}
          >
            <ErrorPage.Heading>
              {title || <T {...messages.notFoundTitle} />}
            </ErrorPage.Heading>
            {children && <div className="mb-6 text-grey-50">{children}</div>}
          </div>
          {cta && <div className="mb-6 md:ml-6">{cta}</div>}
        </div>
      </div>
      {illustration && (
        <ErrorPage.Illustration>{illustration}</ErrorPage.Illustration>
      )}
    </ErrorPage.Layout>
  );
};

ErrorStatusPage.displayName = 'ErrorStatusPage';

export default ErrorStatusPage;
